export function getPrimaryColorButton(user) {
  if (!user || !user.coresPadroes || !user.coresPadroes.primaryColorButton) {
    return "#0063D5";
  }
  return user.coresPadroes.primaryColorButton;
}

export function getLightColorButton(user) {
  if (!user || !user.coresPadroes || !user.coresPadroes.lightColorButton) {
    return "#89c0ff";
  }
  return user.coresPadroes.lightColorButton;
}

export function getTopBarColor(user) {
  if (!user || !user.coresPadroes || !user.coresPadroes.topBarColor) {
    return "#2e312e";
  }

  return user.coresPadroes.topBarColor;
}

export function getLogoLightBackground() {
  return "/logo_quitapay.png";
}

export function getLogoDarkBackground() {
  return "/logo_branco_quitapay.svg";
}

export function getFavicon() {
  return "/favicon_quitapay.png";
}
