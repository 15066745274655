import { useSelector } from "react-redux";
export default function LogoFundoEscuro(props) {
  const user = useSelector((state) => state.user);
  return (
    <>
      {user?.logos?.darkBackground && (
        <img
          src={user.logos.darkBackground}
          style={{ maxWidth: "100px" }}
          alt=""
          {...props}
        />
      )}
    </>
  );
}
