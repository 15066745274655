import { lazy } from "react";
import Loadable from "@/components/Loadable";
import MainLayoutParceiro from "@/layout/MainLayoutParceiro";
import { Navigate } from "react-router-dom";

const FinalizacaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/simular/FinalizacaoParceiro"))
);

const Biometria = Loadable(
  lazy(() => import("@/pages/common/biometria/Biometry"))
);
const BiometriaCam = Loadable(
  lazy(() => import("@/pages/common/biometria/biometryCam/BiometryCam"))
);

const Pagamento3ds = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento3ds"))
);

const Atualizacoes = Loadable(
  lazy(() => import("@/pages/parceiro/atualizacoes-sistema"))
);
const SimulacaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/simular/SimulacaoParceiro"))
);

const SimuladorComissionamento = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/simulador-comissionamento"))
);

const Pagamento = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento"))
);
const ControleAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso"))
);
const Usuarios = Loadable(lazy(() => import("@/pages/parceiro/usuarios")));

const LinkPagamento = Loadable(
  lazy(() => import("@/pages/common/simulacao/LinkPagamento"))
);
const FinalizacaoSemValidacaoMesa = Loadable(
  lazy(() => import("@/pages/common/simulacao/FinalizacaoSemValidacaoMesa"))
);
const PerfilAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso/perfilAcesso"))
);
const CadastroPerfilAcesso = Loadable(
  lazy(() => import("@/pages/parceiro/controle-acesso/perfilAcesso/cadastro"))
);

const ConfigurarComissionamento = Loadable(
  lazy(() =>
    import("@/pages/parceiro/comissoes-gerais/configurar-comissionamento")
  )
);
const ConfigurarComissionamentoUsuario = Loadable(
  lazy(() =>
    import(
      "@/pages/parceiro/comissoes-gerais/configurar-comissionamento-usuario"
    )
  )
);

const Usuario = Loadable(
  lazy(() => import("@/pages/parceiro/usuarios/usuario"))
);

const LinksGerados = Loadable(
  lazy(() => import("@/pages/parceiro/links-gerados"))
);

const Pedidos = Loadable(lazy(() => import("@/pages/parceiro/pedidos")));
const Pedido = Loadable(lazy(() => import("@/pages/parceiro/pedidos/pedido")));
const DashboardDefault = Loadable(
  lazy(() => import("@/pages/parceiro/dashboard-parceiro"))
);
const AlterarSenha = Loadable(
  lazy(() => import("@/pages/parceiro/alterar-senha"))
);

const MeusDados = Loadable(lazy(() => import("@/pages/parceiro/meus-dados")));
const Parceiros = Loadable(lazy(() => import("@/pages/parceiro/parceiros")));

const UsuariosParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/usuarios-parceiro"))
);

const Comissoes = Loadable(
  lazy(() => import("@/pages/parceiro/comissoes-gerais"))
);

const CadastroParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/cadastro-parceiro"))
);
const EdicaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/parceiros/edicao-parceiro"))
);

const Avisos = Loadable(
  lazy(() =>
    import("@/pages/parceiro/dashboard-parceiro/avisos/ListagemAvisos")
  )
);
const DetalhesAviso = Loadable(
  lazy(() => import("@/pages/parceiro/dashboard-parceiro/avisos/DetalhesAviso"))
);
const ConfiguracoesAvisos = Loadable(
  lazy(() =>
    import("@/pages/parceiro/dashboard-parceiro/avisos/configuracoes-avisos")
  )
);

const Configuracoes = Loadable(
  lazy(() => import("@/pages/parceiro/configuracoes"))
);

const ComoFunciona = Loadable(
  lazy(() => import("@/pages/parceiro/como-funciona"))
);

const ConfiguracoesEmail = Loadable(
  lazy(() => import("@/pages/parceiro/configuracoes/configuracoes-email"))
);

const IndiqueEGanhe = Loadable(
  lazy(() => import("@/pages/parceiro/indique-e-ganhe"))
);

const DadosPessoaisParceiro = Loadable(
  lazy(() => import("@/pages/common/meus-dados/MeusDados"))
);

const HistoricoSaldo = Loadable(
  lazy(() => import("@/pages/parceiro/comissoes-gerais/meus-saldos/historico"))
);

const Comprovante = Loadable(
  lazy(() =>
    import("@/pages/parceiro/pedidos/pedido/pagamento-solicitado/comprovante")
  )
);

const ComprovanteTransferencia = Loadable(
  lazy(() =>
    import("@/pages/parceiro/comissoes-gerais/meus-saldos/comprovante")
  )
);

const MainRoutes = {
  path: "dashboard",
  element: <MainLayoutParceiro />,
  children: [
    {
      path: "",
      element: <DashboardDefault />,
    },
    {
      path: "operacoes",
      element: <Pedidos />,
    },
    {
      path: "como-funciona",
      element: <ComoFunciona />,
    },
    {
      path: "atualizacoes-do-sistema",
      element: <Atualizacoes />,
    },

    {
      path: "simular-comissionamento",
      element: <SimuladorComissionamento />,
    },

    {
      path: "simular-comissionamento/:billValue/:idComissionTable",
      element: <SimuladorComissionamento />,
    },
    {
      path: "comissoes-gerais",
      element: <Comissoes />,
    },
    {
      path: "historico-saldo",
      element: <HistoricoSaldo />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoParceiro />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoParceiro />,
    },
    {
      path: "simular",
      element: <Navigate {...{ to: "/dashboard/simulacao/etapa/1" }} />,
    },

    {
      path: "biometria/:idRequest/:token",
      element: <Biometria />,
    },
    {
      path: "biometria/camera/:idRequest/:token",
      element: <BiometriaCam />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "meus-dados",
      element: <MeusDados />,
    },
    {
      path: "parceiros",
      element: <Parceiros />,
    },
    {
      path: "indique-e-ganhe",
      element: <IndiqueEGanhe />,
    },
    {
      path: "*",
      element: <DashboardDefault />,
    },
    {
      path: "usuario",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany/:idUser",
      element: <Usuario />,
    },
    {
      path: "meus-dados/:idCompany/:idUser",
      element: <DadosPessoaisParceiro />,
    },
    {
      path: "usuarios-parceiro/:idCompany",
      element: <UsuariosParceiro />,
    },
    {
      path: "clientes",
      element: <Usuarios />,
    },

    {
      path: "link-pagamento",
      element: <LinkPagamento />,
    },
    {
      path: "parceiro",
      element: <CadastroParceiro />,
    },
    {
      path: "links-gerados",
      element: <LinksGerados />,
    },
    {
      path: "perfis-acesso",
      element: <PerfilAcesso />,
    },

    {
      path: "controle-acesso",
      element: <ControleAcesso />,
    },
    {
      path: "perfil-acesso",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "perfil-acesso/:idAccessProfile",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "configurar-comissionamento",
      element: <ConfigurarComissionamento />,
    },
    {
      path: "configurar-comissionamento/:idCompany",
      element: <ConfigurarComissionamento />,
    },
    {
      path: "configurar-comissionamento-usuario",
      element: <ConfigurarComissionamentoUsuario />,
    },
    {
      path: "parceiro/:idCompany",
      element: <EdicaoParceiro />,
    },
    {
      path: "pagamento/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token",
      element: <Pagamento />,
    },

    {
      path: "pagamento-ep/:idRequest/:token",
      element: <Pagamento3ds />,
    },
    {
      path: "confirmacao/:idRequest/:token",
      element: <FinalizacaoParceiro />,
    },

    {
      path: "operacao-finalizada",
      element: <FinalizacaoSemValidacaoMesa />,
    },
    {
      path: "avisos",
      element: <Avisos />,
    },
    {
      path: "avisos/aviso/:id",
      element: <DetalhesAviso />,
    },
    {
      path: "avisos/configuracoes",
      element: <ConfiguracoesAvisos />,
    },
    {
      path: "configuracoes",
      element: <Configuracoes />,
    },
    {
      path: "configuracoes/email",
      element: <ConfiguracoesEmail />,
    },
    {
      path: "recibo-operacao/:idRequest/:token/:idRequestBill",
      element: <Comprovante />,
    },
    {
      path: "comprovante-transferencia/:idTransfer",
      element: <ComprovanteTransferencia />,
    },
  ],
};

export default MainRoutes;
