import { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { post } from "@/services/api";
import LogoFundoEscuro from "@/components/LogoFundoEscuro";
import Profile from "./Profile";
import MobileSection from "./MobileSection";

const HeaderContent = () => {
  const navigation = useNavigate();
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const inicio = () => {
    navigation("/app/area-cliente/inicio");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      post("secure/user/activate");
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {!matchesXs && (
        <Box
          sx={{ width: "100%", ml: { xs: 0, md: 1 } }}
          style={{ paddingTop: "6px" }}>
          <LogoFundoEscuro
            onClick={inicio}
            style={{ height: "30px", cursor: "pointer" }}
          />
        </Box>
      )}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
