import axios from 'axios';
import { ehHomologacao, ehProducao } from './ambiente';

async function perdaAutorizacao() {
  alert('Por favor, faça login novamente!');
  sessionStorage.removeItem('dadosUsuarioLogado');
  window.location.href = '/app/login';
}

export function retornaUsuario(login) {
  let usuarioLogadoStorage = {};
  if (!login && !usuarioLogadoStorage) {
    perdaAutorizacao();
    return;
  }
  return usuarioLogadoStorage;
}

function retornaHeaders() {
  let usuarioLogado = {};
  try {
    const dadosUsuario = sessionStorage.getItem('dadosUsuarioLogado');
    if (!dadosUsuario || dadosUsuario === 'null') {
      usuarioLogado = {};
    } else {
      usuarioLogado = JSON.parse(dadosUsuario);
    }
  } catch (e) {}
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + (usuarioLogado.userToken || ''),
    },
  };
}

export function retornaUrlBase() {
  if (ehHomologacao()) {
    return 'https://api.homologacao.quitapay.com/';
  }
  if (ehProducao()) {
    return 'https://api.quitaboletos.com/';
  }

  return 'http://localhost:21092/';
}

function retornaUrlServer() {
  let url = retornaUrlBase();
  return url + 'internal-client/';
}

export function getCompanyBindParam(endpoint) {
  const idCompanyBindWhitelabel = sessionStorage.getItem(
    'idCompanyBindWhitelabel'
  );
  if (!!idCompanyBindWhitelabel) {
    if (endpoint.indexOf('?') === -1) {
      return `?idCompanyBindWhitelabel=${idCompanyBindWhitelabel}`;
    }
    return `&idCompanyBindWhitelabel=${idCompanyBindWhitelabel}`;
  }
  return '';
}
export function post(endpoint, body) {
  var promise = new Promise((resolve, reject) => {
    if (!body) {
      body = {};
    }
    endpoint += getCompanyBindParam(endpoint);
    axios
      .post(retornaUrlServer() + endpoint, body, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

const trataErro = (error, reject) => {
  if (error.response && error.response.status === 401) {
    perdaAutorizacao();
    return;
  }
  reject(error);
};
export function get(endpoint) {
  var promise = new Promise((resolve, reject) => {
    endpoint += getCompanyBindParam(endpoint);
    axios
      .get(retornaUrlServer() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}

export function remove(endpoint) {
  var promise = new Promise((resolve, reject) => {
    endpoint += getCompanyBindParam(endpoint);
    axios
      .delete(retornaUrlServer() + endpoint, retornaHeaders())
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        trataErro(error, reject);
      });
  });
  return promise;
}
