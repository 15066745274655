import MainLayout from "@/layout/MainLayout";
import { lazy } from "react";
import Loadable from "@/components/Loadable";
const FinalizacaoSemValidacaoMesa = Loadable(
  lazy(() => import("@/pages/common/simulacao/FinalizacaoSemValidacaoMesa"))
);

const Historico = Loadable(
  lazy(() => import("@/pages/cliente/areaPrivada/historico"))
);

const Biometria = Loadable(
  lazy(() => import("@/pages/common/biometria/Biometry"))
);
const BiometriaCam = Loadable(
  lazy(() => import("@/pages/common/biometria/biometryCam/BiometryCam"))
);

const Pedido = Loadable(
  lazy(() => import("@/pages/cliente/areaPrivada/historico/pedido-detalhes"))
);

const Inicio = Loadable(
  lazy(() => import("@/pages/cliente/areaPrivada/inicio"))
);

const MeusDados = Loadable(
  lazy(() => import("@/pages/common/meus-dados/MeusDados"))
);

const SimulacaoClienteComLogin = Loadable(
  lazy(() =>
    import("@/pages/cliente/areaAberta/simulacao/SimulacaoClienteComLogin")
  )
);
const PoliticaIndicacao = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/politica-indicacao"))
);

const Finalizacao = Loadable(
  lazy(() => import("@/pages/common/simulacao/Finalizacao"))
);

const Pagamento = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento"))
);

const Pagamento3ds = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento3ds"))
);
const AlterarSenha = Loadable(
  lazy(() => import("@/pages/cliente/areaPrivada/alterar-senha/AlterarSenha"))
);

const AcessoAdmin = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/login/accessAdmin"))
);

const Comprovante = Loadable(
  lazy(() =>
    import(
      "@/pages/cliente/areaPrivada/historico/pedido-detalhes/pagamento-solicitado/comprovante"
    )
  )
);

const MainRoutes = {
  path: "app/area-cliente",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Inicio />,
    },
    {
      path: "inicio",
      element: <Inicio />,
    },
    {
      path: "pedidos",
      element: <Historico />,
    },
    {
      path: "acesso-admin/:token/:screen",
      element: <AcessoAdmin />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },
    {
      path: "simulacao",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "confirmacao/:idRequest/:token",
      element: <Finalizacao />,
    },
    {
      path: "operacao-finalizada",
      element: <FinalizacaoSemValidacaoMesa />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "simulacao/cupom/:cupomUtilizar",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoClienteComLogin />,
    },
    {
      path: "politica-indicacao",
      element: <PoliticaIndicacao />,
    },
    {
      path: "biometria/:idRequest/:token",
      element: <Biometria />,
    },
    {
      path: "biometria/camera/:idRequest/:token",
      element: <BiometriaCam />,
    },
    {
      path: "pagamento/:idRequest/:token",
      element: <Pagamento />,
    },

    {
      path: "pagamento-ep/:idRequest/:token",
      element: <Pagamento3ds />,
    },

    {
      path: "pagamento/erro/:idRequest/:token",
      element: <Pagamento />,
    },

    {
      path: "meus-dados",
      element: <MeusDados />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "recibo-operacao/:idRequest/:token/:idRequestBill",
      element: <Comprovante />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default MainRoutes;
