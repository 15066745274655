import { appendHeaderScript } from "./ambiente";

export function adicionaScriptsHeader(scripts) {
  if (!scripts || scripts.length === 0) {
    return;
  }
  for (let i = 0; i < scripts.length; i++) {
    let script = scripts[i];
    appendHeaderScript(script);
  }
}

export function adicionaScriptsQuita() {
  const konduto = {
    type: "text",
    script: `(function (a, b, c, d, e, f, g) {
        a["CsdpObject"] = e;
        (a[e] =
          a[e] ||
          function () {
            (a[e].q = a[e].q || []).push(arguments);
          }),
          (a[e].l = 1 * new Date());
        (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
        f.async = 1;
        f.src = d;
        g.parentNode.insertBefore(f, g);
      })(
        window,
        document,
        "script",
        "//device.clearsale.com.br/p/fp.js",
        "csdp"
      );
      csdp("app", "7o7rddnna9vezwfdelx5");
      csdp("outputsessionid", "antiFraudIdQuitaBoletos");`,
  };
  const tagManager1 = {
    type: "src",
    script: `https://www.googletagmanager.com/gtag/js?id=G-07MR8EPZ4B`,
  };
  const tagManager2 = {
    type: "text",
    script: `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-07MR8EPZ4B');`,
  };
  const tagManager3 = {
    type: "text",
    script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-NS2MGMB');`,
  };
  const facebookPixel = {
    type: "text",
    script: `!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window, document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '1026834754935900');
                        fbq('track', 'PageView');`,
  };

  appendHeaderScript(konduto);
  appendHeaderScript(tagManager1);
  appendHeaderScript(tagManager2);
  appendHeaderScript(tagManager3);

  appendHeaderScript(facebookPixel);
}
